export interface Config {
  env: string;
  bookingEngine: {
    endpoint: string
  };
  google: {
    clientId: string;
    allowedDomains: string[];
  };
  stripe: {
    publicKey: string;
  }
  idle: {
    timeoutMin: number
  };
  messaging: {
    pusher: {
      key: string;
      cluster: string;
    },
    property: {
      TheCoachHouse: string;
    }
  };
  tables: {
    balances: {
      paginationSize: number
    },
    reservations: {
      paginationSize: number
    }
  },
  propertyAmenities: string[];
  propertyAmenitiesCustomSuggestions: string[];
}

export const config: Config = {
  env: process.env.NODE_ENV,
  bookingEngine: {
    endpoint: process.env.REACT_APP_BOOKING_ENDPOINT || ''
  },
  stripe: {
    publicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY || ''
  },
  google: {
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID || '',
    allowedDomains: process.env.REACT_APP_ALLOWED_DOMAINS
      ? process.env.REACT_APP_ALLOWED_DOMAINS.split(',')
      : [],
  },
  idle: {
    timeoutMin: +process.env.REACT_APP_IDLE_TIMEOUT_MIN! || 15
  },
  messaging: {
    pusher: {
      key: process.env.REACT_APP_PUSHER_KEY || '',
      cluster: process.env.REACT_APP_PUSHER_CLUSTER || '',
    },
    property: {
      TheCoachHouse: process.env.REACT_APP_PROPERTY_PHONE_NUMBER!
    }
  },
  tables: {
    balances: {
      paginationSize: +process.env.REACT_APP_BALANCES_PAGE_SIZE! || 20
    },
    reservations: {
      paginationSize: +process.env.REACT_APP_RESERVATIONS_PAGE_SIZE! || 20
    }
  },
  propertyAmenities: process.env.REACT_APP_PROPERTY_AMENITIES
    ? process.env.REACT_APP_PROPERTY_AMENITIES.split(',').map((a) => a.trim())
    : [],
  propertyAmenitiesCustomSuggestions: process.env.REACT_APP_PROPERTY_AMENITIES_CUSTOM_SUGGESTIONS ? process.env.REACT_APP_PROPERTY_AMENITIES_CUSTOM_SUGGESTIONS.split(',').map((a) => a.trim())
    : []
};

export const AwsConfigAuth = {
  region: process.env.REACT_APP_AWS_AUTH_REGION,
  userPoolId: process.env.REACT_APP_AWS_AUTH_POOL_USER_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_AUTH_POOL_CLIENT_ID,
  localStorage: {
    // domain: process.env.REACT_APP_AWS_AUTH_COOKIE_STORAGE_DOMAIN,
    path: "/",
    expires: 365,
    sameSite: "strict",
    secure: true,
  },
  authenticationFlowType: "USER_SRP_AUTH",
};