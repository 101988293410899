import { createContext } from 'react';
import { Property } from '../../interfaces';

export interface PropertiesContextProps {
  propertiesList?: Property[];

  propertiesLoading: boolean;
}

export const PropertiesContext = createContext<PropertiesContextProps | undefined>(
  undefined,
);
