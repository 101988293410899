import { useState, useEffect, ChangeEvent, FC, useContext } from 'react';
import { Form } from 'react-bootstrap';

import { MultiSelect, Option } from 'react-multi-select-component';
import {
  useAuth,
  useLoading,
  useNotifications,
  useRequest,
} from '../../../modules/hooks';

import { endpoints } from '../../../modules/mappers/urls';
import { ModalType } from '../../../modules/enums/types';
import {
  Property,
  PropertyRequest,
  Roles,
  SelectType,
  Users,
} from '../../../modules/interfaces';
import { UserStatus } from '../../../modules/enums/status';
import { UserAction } from '../../../modules/enums/actions';
import { getApiErrorMessage } from '../../../modules/utils/transform';
import { ModalComponent } from '../UserModals';
import './styles/index.css';
import { PropertiesContext } from '../../../modules/context/propertiesContext';
import { UserPropertiesContext } from '../../../modules/context/userPropertiesContext';
import { ResetCodeModal } from './resetPasswordModal';

export interface UserUpdateRequest {
  firstName: string;
  lastName: string;
  role: string;
  properties: PropertyRequest[];
}
export interface UserStatusRequest {
  status: string;
}
export interface UserManagmentModalData {
  handleClose: () => void;
  handleRefresh: (token: string) => void;
  modalType: ModalType;
  modalName: string;
  cancelButton: string;
  saveButton: string;
  roles?: Roles[];
  data?: Users;
}

interface UserModalProps {
  type: ModalType;
}

interface UserModalBodyProps extends UserModalProps {
  firstName: string;
  lastName: string;
  email: string;
  roleId?: string;
  roles?: Roles[];
  properties?: Option[];
  onChangeFirstName: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeLastName: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeRole: (e: ChangeEvent<HTMLSelectElement>) => void;
  onChangeProperties: React.Dispatch<React.SetStateAction<Option[]>>;
  onChangeEmail: (e: ChangeEvent<HTMLInputElement>) => void;
  propertiesList: Property[];
}

const ModalBody: FC<UserModalBodyProps> = ({
  type,
  firstName,
  lastName,
  properties,

  email,
  roleId,
  roles = [],
  onChangeFirstName,
  onChangeLastName,
  onChangeRole,
  onChangeProperties,
  onChangeEmail,
  propertiesList,
}) => {
  const [selectedProperties, setSelectedProperties] = useState<Option[]>([]);

  let component: JSX.Element | null = null;

  useEffect(() => {
    if (!properties) return;
    setSelectedProperties(properties);
  }, [properties]);

  const getPropertyDropDownData = () => {
    const dropDownList: SelectType[] = [];

    propertiesList.forEach(item => {
      dropDownList.push({
        value: item.uuid,
        label: item.name,
      });
    });
    return dropDownList;
  };

  if (type === ModalType.EDIT) {
    component = (
      <Form>
        <Form.Group>
          <Form.Label>First name</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="First name"
            value={firstName}
            onChange={onChangeFirstName}
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Last name</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Last name"
            value={lastName}
            onChange={onChangeLastName}
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Role</Form.Label>
          <Form.Select value={roleId} onChange={onChangeRole}>
            {roles.map(role => {
              return <option value={role.uuid}>{role.name}</option>;
            })}
          </Form.Select>
        </Form.Group>

        <Form.Group controlId="formEditUser">
          <Form.Label>Properties</Form.Label>
          <MultiSelect
            options={getPropertyDropDownData()}
            value={selectedProperties}
            onChange={(values: Option[]) => {
              setSelectedProperties(values);
              onChangeProperties(values);
            }}
            labelledBy="Select"
          />
        </Form.Group>
      </Form>
    );
  } else if (type === ModalType.RESET_PASSWORD) {
    component = (
      <Form>
        <Form.Group controlId="formEditUser">
          <Form.Label>User Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={onChangeEmail}
          />
        </Form.Group>
      </Form>
    );
  }

  return component;
};
const ModalTitle: FC<UserModalProps> = ({ type }) => {
  let component: JSX.Element | null = null;

  if (type === ModalType.EDIT) {
    component = (
      <>
        Edit User <br /> <span>Edit this User</span>
      </>
    );
  } else if (type === ModalType.SUSPEND_USER) {
    component = (
      <>
        Suspend User <br />
        <span>Are you sure you want to suspend this user?</span>
      </>
    );
  } else if (type === ModalType.ACTIVATE_USER) {
    component = (
      <>
        Activate User <br />
        <span>Are you sure you want to activate this user?</span>
      </>
    );
  } else if (type === ModalType.RESET_PASSWORD) {
    return <>Reset Password</>;
  } else if (type === ModalType.DELETE) {
    component = (
      <>
        Delete User <br />
        <span className="delete-message">Are you sure you want to delete this user?</span>
      </>
    );
  }

  return component;
};

export const UserManagmentOption: FC<UserManagmentModalData> = ({
  modalName,
  cancelButton,
  saveButton,
  data,
  roles,
  modalType,
  handleClose,
  handleRefresh,
}) => {
  const { setLoading } = useLoading()!;
  const { credentialsInfo } = useAuth()!;
  const [showModal, setShowModal] = useState('');
  const [footerBtn1, setFooterBtn1] = useState('');
  const [footerBtn2, setFooterBtn2] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [roleId, setRoleId] = useState('');
  const [resetCode, setResetCode] = useState('');
  const [showResetCodeModal, setShowResetCodeModal] = useState(false);
  const { setSimpleToasts } = useNotifications()!;
  const [selectedProperties, setSelectedProperties] = useState<Option[]>([]);

  const { propertiesList } = useContext(PropertiesContext)!;
  const { getInfo } = useContext(UserPropertiesContext)!;

  const getPropertyData = (): PropertyRequest[] => {
    const propertiesIds: PropertyRequest[] = selectedProperties.map(item => {
      return { propertyId: item.value, isDefault: false };
    });
    return propertiesIds;
  };

  const url = `${endpoints.USERS}/${email}`;
  const [{ loading: updateLoading, data: updateResult, error: updateError }, updateUser] =
    useRequest<UserUpdateRequest>(
      url,
      'patch',
      {
        authToken: credentialsInfo?.token,
      },
      { manual: true },
    );

  const urlResetPassword = `${endpoints.USERS}/reset-password`;
  const [{ data: resetResult }, resetPassword] = useRequest<string>(
    urlResetPassword,
    'post',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  useEffect(() => {
    if (!resetResult) return;
    setResetCode(resetResult.result);
    setShowResetCodeModal(true);
  }, [resetResult]);

  useEffect(() => {
    setShowModal(modalName);
    setFooterBtn1(cancelButton);
    setFooterBtn2(saveButton);

    if (data) {
      const [selectedRole] = data.roles;
      setFirstName(data.firstName);
      setLastName(data.lastName);
      setEmail(data.email);

      const properties = data.userProperties?.map(item => ({
        value: item.property.uuid,
        label: item.property.name,
      }));

      setSelectedProperties(properties || []);

      if (selectedRole) {
        setRoleId(selectedRole.uuid);
      }
    }
  }, [setShowModal, cancelButton, saveButton, data, modalName]);

  useEffect(() => {
    setLoading(updateLoading);
  }, [updateLoading, setLoading]);

  useEffect(() => {
    if (updateError) {
      const message = getApiErrorMessage(updateError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
    if (updateResult) {
      getInfo();
      setSimpleToasts({ type: 'info', message: 'User Updated successly!', show: true });
      handleClose();
      handleRefresh(credentialsInfo?.token || '');
    }
  }, [updateResult, updateError, handleClose, handleRefresh, setSimpleToasts]);

  const onChangeFirstName = (e: ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.currentTarget.value);
  };

  const onChangeLastName = (e: ChangeEvent<HTMLInputElement>) => {
    setLastName(e.currentTarget.value);
  };
  const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
  };

  const onChangeRole = (e: ChangeEvent<HTMLSelectElement>) => {
    setRoleId(e.currentTarget.value);
  };

  let modalClass = '';

  if (
    showModal === UserAction.DELETE_USER ||
    showModal === UserAction.SUSPEND_USER ||
    showModal === UserAction.ACTIVATE_USER
  ) {
    modalClass = 'suspend--user-modal';
  } else if (showModal === UserAction.RESET_PASSWORD) {
    modalClass = 'resetpass--user-modal';
  } else if (showModal === UserAction.EDIT_USER) {
    modalClass = 'user--role-modal new-role-modal edit--user-modal';
  }

  return (
    <div className="user--management-opt">
      {propertiesList && (
        <ModalComponent
          show={!!showModal}
          className={modalClass}
          handleClose={() => {
            setShowModal('');
            handleClose();
          }}
          handleSave={() => {
            setShowModal('');
            if (modalType === ModalType.EDIT) {
              const updateData: UserUpdateRequest = {
                firstName,
                lastName,
                role: roleId,
                properties: getPropertyData(),
              };

              updateUser({
                data: updateData,
                headers: {
                  authorization: credentialsInfo?.token,
                },
              });
            } else if (
              modalType === ModalType.SUSPEND_USER ||
              modalType === ModalType.ACTIVATE_USER
            ) {
              const newStatus =
                data?.status === UserStatus.SUSPENDED
                  ? UserStatus.ACTIVE
                  : UserStatus.SUSPENDED;
              const suspendData: UserStatusRequest = {
                status: newStatus,
              };
              updateUser({
                data: suspendData,
                headers: {
                  authorization: credentialsInfo?.token,
                },
              });
            } else if (modalType === ModalType.RESET_PASSWORD) {
              resetPassword({
                data: { email },
                headers: {
                  authorization: credentialsInfo?.token,
                },
              });
            }
          }}
          aria-labelledby="contained-modal-title-vcenter"
          title={showModal}
          cancelButton={footerBtn1}
          saveButton={footerBtn2}
        >
          <ModalBody
            type={modalType}
            firstName={firstName}
            lastName={lastName}
            onChangeFirstName={onChangeFirstName}
            onChangeLastName={onChangeLastName}
            onChangeRole={onChangeRole}
            onChangeProperties={setSelectedProperties}
            onChangeEmail={onChangeEmail}
            email={email}
            roleId={roleId}
            roles={roles}
            propertiesList={propertiesList}
            properties={selectedProperties}
          />
          <ModalTitle type={modalType} />
        </ModalComponent>
      )}

      {resetCode && (
        <ResetCodeModal
          show={showResetCodeModal}
          handleClose={() => {
            setShowResetCodeModal(false);
          }}
          guestEmail={email}
          resetCodeValue={resetCode}
        />
      )}
    </div>
  );
};
