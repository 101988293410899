import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';

import { Property } from '../../../modules/interfaces';

import { useAuth, useRequest } from '../../../modules/hooks';
import { endpoints } from '../../../modules/mappers/urls';
import { PropertiesContext } from '../../../modules/context/propertiesContext';

export const PropertyProvider: FC<PropsWithChildren> = ({ children }) => {
  const [propertiesList, setPropertiesList] = useState<Property[]>([]);
  const { credentialsInfo } = useAuth()!;

  const userPropertiesUrl = `${endpoints.PROPERTIES}`;
  const [{ data: propertiesData, loading: propertiesLoading }] = useRequest<Property[]>(
    userPropertiesUrl,
    'get',
    {
      authToken: credentialsInfo?.token,
    },
  );

  useEffect(() => {
    if (!propertiesData) return;

    setPropertiesList(propertiesData.result);
  }, [propertiesData]);

  const propertiesContext = useMemo(() => {
    return { propertiesList, propertiesLoading };
  }, [propertiesList, propertiesLoading]);

  return (
    <PropertiesContext.Provider value={propertiesContext}>
      {children}
    </PropertiesContext.Provider>
  );
};
