import { FC, useEffect, useState, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Button, Spinner } from 'react-bootstrap';
import { useForm, SubmitHandler } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import CodeMirror from '@uiw/react-codemirror';
import { json as codeMirrorJSON } from '@codemirror/lang-json';
import { XCircle, Save as SaveIcon, Hdd } from 'react-bootstrap-icons';
import { Property } from '../../../modules/interfaces';
import { endpoints } from '../../../modules/mappers/urls';
import { useAuth, useNotifications, useRequest } from '../../../modules/hooks';
import { getApiErrorMessage } from '../../../modules/utils/transform';

interface PropertyConfigFormInputs {
  jsonConfig: string;
}

interface PropertyConfigModalProps {
  data?: Property;
  show: boolean;
  handleClose: () => void;
}

export const PropertyConfigModal: FC<PropertyConfigModalProps> = ({
  data: property,
  show,
  handleClose,
}) => {
  const { setSimpleToasts } = useNotifications()!;
  const [propertyConfig, setPropertyConfig] = useState<string>('');
  const [isValidJson, setIsValidJson] = useState<boolean>(true);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<PropertyConfigFormInputs>({
    mode: 'onChange',
  });

  const { credentialsInfo } = useAuth()!;
  const getPropertyConfigUrl = `${endpoints.CONFIG}/details/${property?.uuid}`;
  const getDefaultConfigUrl = `${endpoints.CONFIG}/details/global-app-settings`;

  const [{ data: propertyConfigData, loading, error }, getConfigOfProperty] =
    useRequest<JSON>(getPropertyConfigUrl, 'get', { authToken: credentialsInfo?.token });

  const [
    {
      data: defaultConfigDataResponse,
      loading: defaultConfigDataLoading,
      error: defaultConfigDataResponseError,
    },
    getDefaultConfig,
  ] = useRequest<JSON>(getDefaultConfigUrl, 'get', { authToken: credentialsInfo?.token });

  const [{ error: savePropertyConfigError }, savePropertyConfig] = useRequest<JSON>(
    endpoints.CONFIG,
    'post',
    { authToken: credentialsInfo?.token },
    { manual: true },
  );

  const defaultConfigData = JSON.stringify({ PK: property?.uuid }, null, 2);

  const getModalTitle = () =>
    property ? `Settings of ${property.name}` : 'Settings of Property';

  const onSubmit: SubmitHandler<PropertyConfigFormInputs> = async () => {
    try {
      const parsedConfig = JSON.parse(propertyConfig);
      ['updatedAt', 'createdAt'].forEach(field => delete parsedConfig[field]); // Remove timestamps fields
      await savePropertyConfig({ data: parsedConfig });
      setSimpleToasts({
        type: 'success',
        message: 'Settings saved successfully.',
        show: true,
      });
      reset();
      handleClose();
    } catch (err) {
      setSimpleToasts({
        type: 'danger',
        message: 'An error occurred while saving the settings.',
        show: true,
      });
    }
  };

  const handleEditorChange = (value: string) => {
    setValue('jsonConfig', value, { shouldValidate: true });
    setPropertyConfig(value);
  };

  const handleGetDefaultConfig = () => {
    getDefaultConfig();
  };

  const fetchConfigOfProperty = useCallback(() => {
    if (property) {
      getConfigOfProperty();
    } else {
      setPropertyConfig(defaultConfigData);
    }
  }, [property, getConfigOfProperty, defaultConfigData]);

  useEffect(() => {
    if (show) {
      fetchConfigOfProperty();
    }
  }, [show, fetchConfigOfProperty]);

  useEffect(() => {
    if (error) {
      const message = getApiErrorMessage(error);
      setSimpleToasts({ type: 'danger', message, show: true });
      setPropertyConfig(defaultConfigData);
    } else if (propertyConfigData?.result) {
      setPropertyConfig(JSON.stringify(propertyConfigData.result, null, 2));
    } else {
      setPropertyConfig(defaultConfigData);
    }
  }, [propertyConfigData, error, defaultConfigData, setSimpleToasts]);

  useEffect(() => {
    if (defaultConfigDataResponse?.result) {
      const updatedConfig = { ...defaultConfigDataResponse.result, PK: property?.uuid };
      setPropertyConfig(JSON.stringify(updatedConfig, null, 2));
    }
  }, [defaultConfigDataResponse, property?.uuid]);

  useEffect(() => {
    if (savePropertyConfigError) {
      const message = getApiErrorMessage(savePropertyConfigError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
  }, [savePropertyConfigError, setSimpleToasts]);

  return (
    <Modal
      show={show}
      onHide={() => {
        reset();
        handleClose();
      }}
      size="xl"
      backdrop="static"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header>
          <Modal.Title className="h5">{getModalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading || defaultConfigDataLoading ? (
            <div className="text-center">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <Form.Group controlId="jsonConfig">
              <Form.Label>Environment Settings (JSON)</Form.Label>
              <CodeMirror
                value={propertyConfig}
                extensions={[codeMirrorJSON()]}
                onChange={handleEditorChange}
                theme="light"
              />
              <input
                type="hidden"
                value={propertyConfig}
                {...register('jsonConfig', {
                  required: 'JSON configuration is required.',
                  validate: value => {
                    try {
                      JSON.parse(value);
                      setIsValidJson(true);
                      return true;
                    } catch {
                      setIsValidJson(false);
                      return 'Invalid JSON format.';
                    }
                  },
                })}
              />
              <ErrorMessage
                errors={errors}
                name="jsonConfig"
                render={({ message }) => <span className="error-text">{message}</span>}
              />
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="warning" onClick={handleGetDefaultConfig} className="btn-sm">
            <Hdd className="dropdown-icon me-1" />
            Load default settings
          </Button>
          <div>
            <Button variant="secondary" onClick={handleClose} className="btn-sm">
              <XCircle className="dropdown-icon me-1" />
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primary"
              className="ms-2 btn-sm"
              disabled={!isValidJson}
            >
              <SaveIcon className="dropdown-icon me-1" />
              Save
            </Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
