import {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Badge, Container, Image, Overlay, Popover } from 'react-bootstrap';
import IconTint from 'react-icon-tint';
import { useLocation, useNavigate } from 'react-router-dom';

import './styles/styles.css';
import Artical from '../../assets/artical.svg';
import DownArrow from '../../assets/down-arrow.svg';
import DotMenu from '../../assets/dot-menu.svg';
import Icon from '../../assets/Icon-book-nest.svg';
import Inbox from '../../assets/inbox.svg';
import ReportIcon from '../../assets/report-icon.svg';
import Other from '../../assets/other.svg';
import Packages from '../../assets/packages.svg';
import ProjectManagement from '../../assets/project-management.svg';
import Reservation from '../../assets/reservation.svg';
import UpArrow from '../../assets/up-arrow.svg';
import User from '../../assets/user.svg';
import UserProfile from '../../assets/user-profile.svg';
import UserSetting from '../../assets/user-setting.svg';
import UserLogout from '../../assets/user-logout.svg';
import { useAuth } from '../../modules/hooks';
import { routes } from '../../modules/mappers/urls';
import Property from './property';
import { ReservationPackageContext } from '../../modules/context/guestPackageContext';

type SubMenu = { title: string; path: string; badgeNumber?: number };

interface PageItemProps {
  name: string;
  path: string;
  icon: string;
  multiple?: boolean;
  expand: boolean;
  subMenu?: Array<SubMenu>;
  pageItemBadge?: number;
}

const PageItem: FunctionComponent<PageItemProps> = ({
  name,
  path,
  icon,
  multiple,
  expand,
  subMenu,
  pageItemBadge,
}: PageItemProps) => {
  const location = useLocation();
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (subMenu) {
      const subMenuSelect = subMenu?.filter(item => item.path === location.pathname);
      if (!(location.pathname === path || (subMenuSelect && subMenuSelect.length > 0))) {
        setOpen(false);
      }
    }
  }, [location]);

  const selected = useMemo(() => {
    const subMenuSelect = subMenu?.filter(item => item.path === location.pathname);
    return location.pathname === path || (subMenuSelect && subMenuSelect.length > 0);
  }, [location, path]);

  const handleClick = useCallback(() => {
    if (expand) {
      setOpen(!isOpen);
    }
    navigate(path);
  }, [navigate, path, expand, isOpen]);

  const subMenuSelected = useCallback(
    (item: SubMenu) => {
      return location.pathname === item.path;
    },
    [location],
  );

  const handleSubMenuClick = useCallback(
    (item: SubMenu) => {
      navigate(item.path);
    },
    [navigate],
  );

  return (
    <>
      <Container
        className={
          selected
            ? 'sidebar_container page_item_color'
            : ' sidebar_container page_item_container'
        }
        onClick={handleClick}
      >
        <Container className="sidebar_container page_icon">
          <IconTint color={selected ? '#15CF5F' : '#909090'} src={icon} />
        </Container>
        {expand && (
          <div className={selected ? 'page_name_select' : 'page_name'}>
            {name}
            {pageItemBadge && (
              <Badge bg="warning" text="dark" style={{ marginLeft: '10px' }}>
                {pageItemBadge}
              </Badge>
            )}
          </div>
        )}
        {expand && multiple && <img alt={name} src={isOpen ? UpArrow : DownArrow} />}
      </Container>
      {isOpen &&
        expand &&
        subMenu?.map((item: SubMenu) => {
          return (
            <Container
              className="sidebar_container sub_menu_container"
              onClick={() => handleSubMenuClick(item)}
            >
              <div
                className="sub_menu_name"
                style={{ color: subMenuSelected(item) ? '#FFFFFF' : '#909090' }}
              >
                {item.title}

                {item.badgeNumber && item.badgeNumber > 0 ? (
                  <Badge
                    bg="warning"
                    text="dark"
                    style={{
                      marginLeft: '10px',
                      padding: '1px 6px 1px 6px',
                      fontSize: '10px',
                    }}
                  >
                    {item.badgeNumber}
                  </Badge>
                ) : null}
              </div>
            </Container>
          );
        })}
    </>
  );
};

interface SidebarProps {
  expand: boolean;
}

const Sidebar: FunctionComponent<SidebarProps> = ({ expand }: SidebarProps) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const { signOut, credentialsInfo } = useAuth()!;
  const [pendingPackagesSize, setPendingPackagesSize] = useState(0);

  const reservationSubMenu = [
    { title: 'Reservations', path: routes.RESERVATIONS },
    { title: 'Group Bookings', path: 'Group Bookings' },
  ];
  const articalSubMenu = [
    { title: 'Articles', path: routes.ARTICLES },
    { title: 'Categories', path: routes.CATEGORIES },
  ];
  const packageSubMenu = [
    {
      title: 'Packages',
      path: routes.PACKAGES,
      badgeNumber: pendingPackagesSize,
    },
    {
      title: 'Package Management',
      path: routes.PACKAGES_MANAGEMENT,
    },
  ];
  const propertySubMenu = [{ title: 'Property Management', path: routes.PROPERTIES }];
  const reportSubMenu = [
    { title: 'Occupancy Reports', path: routes.REPORTS },
    { title: 'Revenue Reports', path: routes.REVENUEREPORTS },
    { title: 'Room Revenue Reports', path: routes.ROOMREVENUEREPORTS },
  ];
  const otherSubMenu = [{ title: 'Other', path: routes.MORE }];

  const onUserItemClick = (title: string) => {
    setShow(false);
    switch (title) {
      case 'Log out':
        signOut();
        break;
      default:
    }
  };

  const renderUserItem = (icon: string, title: string) => {
    return (
      <div className="user_item" onClick={() => onUserItemClick(title)}>
        <img alt="user_item_img" src={icon} />
        <div className="user_action">{title}</div>
      </div>
    );
  };

  const reservationPackageList = useContext(ReservationPackageContext);
  const { reservationPackages } = reservationPackageList!;

  useEffect(() => {
    if (!reservationPackages) return;

    const pendingPacakgesSize = reservationPackages.filter(
      item => !(item.status === 'APPROVED'),
    ).length;

    setPendingPackagesSize(pendingPacakgesSize);
  }, [reservationPackages, reservationPackageList]);

  return (
    <Container
      className="sidebar_container"
      fluid
      style={{ width: expand ? '275px' : '75px' }}
    >
      <Container fluid className=" sidebar_container main_container">
        <Container className="logo_container">
          <Image className="logo_image" src={Icon} alt="BookNest Icon" />
          {expand && <div className="logo_text">Lodging</div>}
        </Container>
        <Property expand={expand} />
        <Container className="sidebar_container page_list_container">
          <PageItem
            name="Reserverations"
            path={routes.RESERVATIONS}
            icon={Reservation}
            expand={expand}
            subMenu={reservationSubMenu}
            multiple
          />
          <PageItem name="Inbox" path={routes.INBOX} icon={Inbox} expand={expand} />
          <PageItem
            name="Articles"
            path={routes.ARTICLES}
            icon={Artical}
            multiple
            expand={expand}
            subMenu={articalSubMenu}
          />
          <PageItem
            name="Packages"
            path={routes.PACKAGES}
            icon={Packages}
            multiple
            expand={expand}
            subMenu={packageSubMenu}
            pageItemBadge={pendingPackagesSize > 0 ? pendingPackagesSize : undefined}
          />
          <PageItem
            name="Property Management"
            path={routes.PROPERTIES}
            icon={ProjectManagement}
            expand={expand}
            subMenu={propertySubMenu}
            multiple
          />

          <PageItem name="Rooms" path={routes.ROOMS} icon={Inbox} expand={expand} />
          <PageItem name="Users" path={routes.USERS} icon={Inbox} expand={expand} />

          <PageItem
            name="Reports"
            path={routes.REPORTS}
            icon={ReportIcon}
            expand={expand}
            subMenu={reportSubMenu}
            multiple
          />
          <PageItem
            name="Other"
            path={routes.MORE}
            icon={Other}
            multiple
            expand={expand}
            subMenu={otherSubMenu}
          />
        </Container>
      </Container>
      <Container className="sidebar_container user_container">
        <div className="user_divider" />
        <Container
          className={
            expand
              ? 'sidebar_container user_details_container'
              : 'sidebar_container user_details_enclosed'
          }
        >
          <img className="user_image" alt="user" src={User} />
          {expand && (
            <div className="property_details_container">
              <div className="property__name_text">{credentialsInfo?.name}</div>
              <div className="property_text">{credentialsInfo?.email}</div>
            </div>
          )}
          {expand && (
            <div className="menu-three-dot" ref={target} onClick={() => setShow(!show)}>
              <img alt="menu" src={DotMenu} />
            </div>
          )}
          <Overlay
            rootClose
            target={target.current}
            show={show}
            placement="right-start"
            onHide={() => setShow(false)}
          >
            <Popover id="popover-positioned-bottom">
              <Popover.Body className="user_overlay">
                {renderUserItem(UserProfile, 'Profile')}
                {renderUserItem(UserSetting, 'Settings')}
                <div className="user_overlay_divider" />
                {renderUserItem(UserLogout, 'Log out')}
              </Popover.Body>
            </Popover>
          </Overlay>
        </Container>
      </Container>
    </Container>
  );
};

export default Sidebar;
