import { ChangeEvent, FC, useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import { MultiSelect, Option } from 'react-multi-select-component';
import { useNotifications } from '../../../modules/hooks';
import { PropertyRequest, Roles, SelectType } from '../../../modules/interfaces';

import './styles/index.css';

import { PropertiesContext } from '../../../modules/context/propertiesContext';

export interface InviteModalData {
  showInviteModal: boolean;
  roleList: Roles[];
  handleClose: () => void;
  handleSave: (email: string, role: string, properties: PropertyRequest[]) => void;
}

export const InviteModal: FC<InviteModalData> = ({
  showInviteModal,
  roleList,
  handleClose,
  handleSave,
}) => {
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const { setSimpleToasts } = useNotifications()!;
  const [selectedProperties, setSelectedProperties] = useState<Option[]>([]);

  const { propertiesList } = useContext(PropertiesContext)!;

  const onChangeEmailInput = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
  };

  const onChangeRole = (e: ChangeEvent<HTMLSelectElement>) => {
    setRole(e.currentTarget.value);
  };

  const getPropertyData = (): PropertyRequest[] => {
    const propertiesIds: PropertyRequest[] = selectedProperties.map(item => {
      return { propertyId: item.value, isDefault: false };
    });
    return propertiesIds;
  };

  const handleSubmit = () => {
    if (email && role) {
      handleSave(email, role, getPropertyData());
    } else {
      setSimpleToasts({
        type: 'danger',
        message: 'Please be sure to select a role and input an email.',
        show: true,
      });
    }
  };

  const getPropertyDropDownData = () => {
    const dropDownList: SelectType[] = [];

    propertiesList?.forEach(item => {
      dropDownList.push({
        value: item.uuid,
        label: item.name,
      });
    });
    return dropDownList;
  };

  return (
    <Modal
      className="invite--user-modal user--role-modal"
      show={showInviteModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton className="border-0">
        <Modal.Title>
          Invite User <br /> <span>Add new User</span>{' '}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>User Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              onChange={onChangeEmailInput}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Role</Form.Label>
            <Form.Select className="form-select" onChange={onChangeRole}>
              <option value="">Select a Role</option>
              {roleList.map(item => {
                return <option value={item.uuid}>{item.name}</option>;
              })}
            </Form.Select>
          </Form.Group>
        </Form>
        <Form.Label>Properties</Form.Label>
        <MultiSelect
          options={getPropertyDropDownData()}
          value={selectedProperties}
          onChange={(values: Option[]) => {
            setSelectedProperties(values);
          }}
          labelledBy="Select"
        />
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-between">
        <Button className="cancel-button" onClick={handleClose}>
          Cancel
        </Button>
        <Button className="save-button" onClick={handleSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
