import { FC, useEffect, useState, useCallback, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import Dropdown from 'react-bootstrap/Dropdown';
import { Button, Container, Image } from 'react-bootstrap';

import { PencilSquare, Trash, Clipboard } from 'react-bootstrap-icons';
import { DataTable, ImageLabelCellRenderer } from '../DataTable';
import {
  useAuth,
  useRequest,
  useLoading,
  useNotifications,
} from '../../../modules/hooks';
import { getApiErrorMessage } from '../../../modules/utils/transform';
import { endpoints } from '../../../modules/mappers/urls';
import { config } from '../../../modules/config';
import { DotDropDown } from '../../Customize/DropDown';
import { RoomResponse } from '../../../modules/interfaces';

import { RoomEditorModal } from '../../Modals/RoomEditor';
import { DeleteRoomModal } from '../../Modals/RoomEditor/DeleteRoomModal';

interface RoomDotCellRendererProps {
  data?: RoomResponse;
  onClickDeleteRoom: (data?: RoomResponse) => void;
  onClickEditRoom: (data?: RoomResponse) => void;
  onClickDuplicateRoom: (data?: RoomResponse) => void;
}

const RoomDotMenu: FC<RoomDotCellRendererProps> = ({
  data,
  onClickDeleteRoom,
  onClickEditRoom,
  onClickDuplicateRoom,
}) => {
  return (
    <DotDropDown title="" drop="end">
      <Dropdown.Item
        onClick={() => {
          onClickEditRoom(data);
        }}
      >
        <PencilSquare className="dropdown-icon" />
        Edit Room
      </Dropdown.Item>

      <Dropdown.Item
        onClick={() => {
          onClickDuplicateRoom(data);
        }}
      >
        <Clipboard className="dropdown-icon" />
        Duplicate Room
      </Dropdown.Item>

      <Dropdown.Item
        onClick={() => {
          onClickDeleteRoom(data);
        }}
      >
        <Trash className="dropdown-icon" />
        Delete Room
      </Dropdown.Item>
    </DotDropDown>
  );
};

const getDotMenu = ({
  onClickDeleteRoom,
  onClickEditRoom,
  onClickDuplicateRoom,
}: RoomDotCellRendererProps) => {
  const DotMenuCellRenderer: FC<ICellRendererParams<RoomResponse>> = (
    params: ICellRendererParams<RoomResponse>,
  ) => {
    const { data } = params;

    return (
      <RoomDotMenu
        data={data}
        onClickDeleteRoom={onClickDeleteRoom}
        onClickEditRoom={onClickEditRoom}
        onClickDuplicateRoom={onClickDuplicateRoom}
      />
    );
  };

  return DotMenuCellRenderer;
};

export const RoomsTable: FC = () => {
  const gridRef = useRef<AgGridReact>(null);
  const { setSimpleToasts } = useNotifications()!;
  const [editData, setEditData] = useState<RoomResponse>();
  const [updateUId, setUpdateUId] = useState<string>();
  const [editModal, setEditModal] = useState<boolean>(false);
  const [duplicate, setDuplicate] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [tableData, setTableData] = useState<RoomResponse[]>([]);
  const { setLoading } = useLoading()!;
  const { credentialsInfo } = useAuth()!;

  const [{ data, loading, error }, refetchRooms] = useRequest<RoomResponse[]>(
    endpoints.ROOMS,
    'get',
    {
      authToken: credentialsInfo?.token,
    },
  );

  const handleAddRoom = () => {
    setUpdateUId('');
    setEditData(undefined);
    setEditModal(true);
  };

  const onClickEditRoom = useCallback(
    (room?: RoomResponse) => {
      if (room) {
        setUpdateUId(room.uuid);
        setEditData(room);
        setEditModal(true);
      }
    },
    [setUpdateUId, setEditData, setEditModal],
  );

  const onClickDuplicateRoom = useCallback(
    (room?: RoomResponse) => {
      if (room) {
        setUpdateUId(room.uuid);
        setDuplicate(true);
        setEditData(room);
        setEditModal(true);
      }
    },
    [setUpdateUId, setEditData, setEditModal],
  );

  const onClickDeleteRoom = useCallback(
    (room?: RoomResponse) => {
      if (room) {
        setUpdateUId(room.uuid);
        setDeleteModal(true);
      }
    },
    [setUpdateUId],
  );

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  useEffect(() => {
    if (error) {
      const message = getApiErrorMessage(error);
      setSimpleToasts({ type: 'danger', message, show: true });
      const mockArray = Array(10);
      const mockData: RoomResponse[] = mockArray.fill(0, 0, 10).map(() => ({
        uuid: '',
        name: 'Room ABC',
        description: 'Room ABC',
        abbreviation: 'ABC',
        checkInSteps: 'string',
        roomSize: '20',
        maxOccupants: '20',
      }));
      setTableData(mockData);
    }

    if (data) {
      setTableData(data.result);
    }
  }, [loading, data, error, setSimpleToasts, setLoading]);

  const refreshRooms = useCallback(async () => {
    await refetchRooms();
  }, [refetchRooms]);

  const [columns] = useState<ColDef<RoomResponse>[]>([
    {
      valueGetter: params => {
        const { data: room } = params;
        const name = room ? `${room.name}` : '-';
        return { name, img: room?.media?.[0]?.uri || 'Icon-room.svg' };
      },
      cellRenderer: ImageLabelCellRenderer,
      headerName: 'Room title',
      width: 200,
    },
    {
      field: 'abbreviation',
      headerName: 'Abbreviation',
      width: 200,
    },
    {
      field: 'roomSize',
      headerName: 'Room size',
      width: 200,
    },
    {
      field: 'bedType',
      headerName: 'Bed type',
    },
    {
      field: 'maxOccupants',
      headerName: 'Max occupants',
    },
    {
      field: 'property.name',
      headerName: 'Property',
    },

    {
      headerName: 'ACTION',
      colId: 'action',
      cellClass: 'actionCol justify-content-end',
      maxWidth: 100,
      cellRenderer: getDotMenu({
        onClickDeleteRoom,
        onClickEditRoom,
        onClickDuplicateRoom,
      }),
    },
  ]);

  return (
    <>
      <Container
        className="table-header"
        style={{ display: 'flex', justifyContent: 'end', marginBottom: '16px' }}
        fluid
      >
        <Button
          id="dropdown-basic-button"
          className="w-auto table-add-button"
          onClick={handleAddRoom}
        >
          <Image alt="" src="/plus.svg" />
          Add Room
        </Button>
      </Container>
      <DataTable
        refreshHandler={refreshRooms}
        gridRef={gridRef}
        changeState={0}
        rowData={tableData}
        columnDefs={columns}
        pagination
        paginationPageSize={config.tables.balances.paginationSize}
      />
      <RoomEditorModal
        show={editModal}
        duplicate={duplicate}
        handleClose={() => {
          setEditModal(false);
          setEditData(undefined);
          setDuplicate(false);
        }}
        data={editData}
        refetchRooms={refetchRooms}
      />

      {updateUId && (
        <DeleteRoomModal
          show={deleteModal}
          handleClose={() => {
            setDeleteModal(false);
          }}
          roomId={updateUId}
          refetchRooms={refetchRooms}
        />
      )}
    </>
  );
};
