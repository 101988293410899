import { createContext } from 'react';
import { RefetchFunction } from 'axios-hooks';
import { Users } from '../../interfaces';

export interface UserPropertiesContextProps {
  userPropertiesData?: Users;

  userPropertiesLoading: boolean;

  getInfo: RefetchFunction<unknown, unknown>;
}

export const UserPropertiesContext = createContext<
  UserPropertiesContextProps | undefined
>(undefined);
