import { FC, useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';

import './styles/styles.css';
import { Col } from 'react-bootstrap';
import { PlusSquareFill } from 'react-bootstrap-icons';
import { Option } from 'react-multi-select-component';

import { PackageCard } from '../../Cards/PackagesCard/PackageCard';
import { AddPackageModal } from '../../Modals/PackagesModals/AddPackageModal';
import { EarlyCheckInModal } from '../../Modals/PackagesModals/EarlyCheckInModal';
import { PackagesResponse } from '../../../modules/interfaces';
import { PackageContext } from '../../../modules/context/packageContext';

export const PackagesManagement: FC = () => {
  const [packages, setPackages] = useState<PackagesResponse[]>([]);
  const [showAddPackageModal, setShowAddPackageModal] = useState(false);
  const [showEarlyCheckModal, setShowEarlyCheckModal] = useState(false);
  const [packageValues, setPackageValues] = useState<PackagesResponse>({});

  const [EarlyCheckParams, setEarlyCheckParams] = useState<Option[]>([]);

  const value = useContext(PackageContext);

  useEffect(() => {
    if (!value?.packages || value?.loading) return;

    const { packages: packagesValue } = value;

    setPackages(packagesValue);
  }, [value?.packages, value?.loading]);

  const getEarlyCheckParams = (values: PackagesResponse, params: Option[]) => {
    setPackageValues(values);
    setEarlyCheckParams(params);
    setShowEarlyCheckModal(true);
  };
  const resetEarlyCheckParams = () => {
    setPackageValues({});
    setEarlyCheckParams([]);
    setShowEarlyCheckModal(true);
    setShowEarlyCheckModal(false);
  };

  return (
    <>
      <Container className="table-header" fluid>
        <Row className="m-0 justify-content-end w-100 align-items-center">
          <Col className="p-0">
            <div className="mt-16px header-title-text">Packages list</div>
          </Col>
        </Row>
      </Container>
      <Container fluid className="mt-16">
        <Row>
          <Col xs={6} sm={4} lg={3} xl={2} style={{ marginTop: 0 }}>
            <Card
              className="package-card new"
              onClick={() => setShowAddPackageModal(true)}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'Center',
                cursor: 'pointer',
              }}
            >
              <PlusSquareFill data-testid="plusSquareId" size={30} color="#14BF59" />

              <span style={{ marginTop: '10px' }}>Add new package</span>
            </Card>
          </Col>
          {packages.map(item => (
            <Col key={item.uuid} xs={6} sm={4} lg={3} xl={2}>
              <PackageCard
                title={item?.name || ''}
                subtitle="Package"
                values={item}
                imageUrl={item?.media?.[0]?.uri || '/no_image.jpg'}
              />
            </Col>
          ))}
        </Row>
      </Container>

      <AddPackageModal
        show={showAddPackageModal}
        handleClose={() => {
          setShowAddPackageModal(false);
        }}
        getEarlyCheckParams={getEarlyCheckParams}
      />
      <EarlyCheckInModal
        show={showEarlyCheckModal}
        handleClose={resetEarlyCheckParams}
        values={packageValues}
        params={EarlyCheckParams}
      />
    </>
  );
};
