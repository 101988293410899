import { ChangeEvent, FC, useState, useEffect, FormEvent } from 'react';
import { Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuth, useNotifications, useRequest } from '../../../modules/hooks';
import { endpoints, routes } from '../../../modules/mappers/urls';
import { getApiErrorMessage } from '../../../modules/utils/transform';
import './styles/styles.css';
import { LogoIcon } from '../../Logo';

interface PasswordStrenthChecker {
  length: boolean;
  hasUpperCase: boolean;
  hasLowerCase: boolean;
  hasDigit: boolean;
  hasSpecialChar: boolean;
}

export const AccepInvite: FC = () => {
  const [inviteId, setInviteId] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loadingSppiner, setLoadingSppiner] = useState(true);
  const { search } = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const [passwordStrengthCheck, setPasswordStrengthCheck] =
    useState<PasswordStrenthChecker>({
      length: false,
      hasUpperCase: false,
      hasLowerCase: false,
      hasDigit: false,
      hasSpecialChar: false,
    });
  const navigate = useNavigate();

  const { setSimpleToasts } = useNotifications()!;
  const { credentialsInfo } = useAuth()!;

  const onChangeFirstName = (e: ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.currentTarget.value);
  };

  const onChangeLastName = (e: ChangeEvent<HTMLInputElement>) => {
    setLastName(e.currentTarget.value);
  };

  const onChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    const passwordValue = e.currentTarget.value;
    const strengthChecks: PasswordStrenthChecker = {
      length: false,
      hasUpperCase: false,
      hasLowerCase: false,
      hasDigit: false,
      hasSpecialChar: false,
    };

    strengthChecks.length = passwordValue.length >= 8;
    strengthChecks.hasUpperCase = /[A-Z]+/.test(passwordValue);
    strengthChecks.hasLowerCase = /[a-z]+/.test(passwordValue);
    strengthChecks.hasDigit = /[0-9]+/.test(passwordValue);
    strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(passwordValue);
    setPassword(passwordValue);
    setPasswordStrengthCheck(strengthChecks);
  };

  const onChangeConfirmPassword = (e: ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.currentTarget.value);
  };

  const url = `${endpoints.USERS}/check-invite/${inviteId}`;
  const [{ data: validateCheckData, error: validateCheckError }, inviteValidationCheck] =
    useRequest<string>(
      url,
      'get',
      {
        authToken: credentialsInfo?.token,
      },
      { manual: true },
    );

  const [{ data: createResult, error: createError }, createUser] = useRequest<string>(
    `${endpoints.USERS}`,
    'post',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  useEffect(() => {
    const params = new URLSearchParams(search);
    const inviteCode = params.get('code');
    if (inviteCode) {
      setInviteId(inviteCode);
    } else {
      setSimpleToasts({ type: 'danger', message: 'No Invited User', show: true });
    }
  }, [search, setSimpleToasts]);

  useEffect(() => {
    if (inviteId !== '') {
      inviteValidationCheck();
    }
  }, [inviteId, inviteValidationCheck, navigate]);

  useEffect(() => {
    if (validateCheckError) {
      const message = getApiErrorMessage(validateCheckError);
      setSimpleToasts({ type: 'danger', message, show: true });
      navigate(routes.LOGIN);
    }
    if (createError) {
      const message = getApiErrorMessage(createError);
      setSimpleToasts({ type: 'danger', message, show: true });
      navigate(routes.LOGIN);
    }
    if (validateCheckData) {
      setLoadingSppiner(false);
    }
    if (createResult) {
      setSimpleToasts({ type: 'info', message: 'You accepted invitation.', show: true });
      navigate(routes.LOGIN);
      setIsLoading(false);
    }
  }, [
    validateCheckData,
    validateCheckError,
    createResult,
    createError,
    setSimpleToasts,
    navigate,
  ]);

  const handleSubmit = (event: FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!passwordStrengthCheck.hasDigit) {
      setSimpleToasts({
        type: 'danger',
        message: 'Password has to include digit',
        show: true,
      });
      return;
    }
    if (!passwordStrengthCheck.hasLowerCase) {
      setSimpleToasts({
        type: 'danger',
        message: 'Password has to include lowercase',
        show: true,
      });
      return;
    }
    if (!passwordStrengthCheck.hasSpecialChar) {
      setSimpleToasts({
        type: 'danger',
        message: 'Password has to include special char',
        show: true,
      });
      return;
    }
    if (!passwordStrengthCheck.hasUpperCase) {
      setSimpleToasts({
        type: 'danger',
        message: 'Password has to include uppercase',
        show: true,
      });
      return;
    }
    if (!passwordStrengthCheck.length) {
      setSimpleToasts({
        type: 'danger',
        message: 'Password has to be more than 8 letters',
        show: true,
      });
      return;
    }

    if (firstName === '') {
      setSimpleToasts({ type: 'danger', message: 'Please input First Name', show: true });
      return;
    }
    if (lastName === '') {
      setSimpleToasts({ type: 'danger', message: 'Please input Last Name', show: true });
      return;
    }
    if (password === '') {
      setSimpleToasts({ type: 'danger', message: 'Please input Password', show: true });
      return;
    }
    if (password !== confirmPassword) {
      setSimpleToasts({
        type: 'danger',
        message: 'Please confirm your password again',
        show: true,
      });
      return;
    }
    createUser({
      data: {
        code: inviteId,
        firstName,
        lastName,
        password,
      },
    });
    setIsLoading(true);
  };

  return (
    <Container className="accept-invite p-4 mt-5">
      <div className="w-100 d-flex justify-content-center">
        <LogoIcon width="35px" height="35px" />
      </div>
      <span className="accept-invite-title text-center w-100">Accept Invite</span>
      {loadingSppiner ? (
        <Spinner animation="border" variant="primary" className="d-block m-auto mt-4" />
      ) : (
        <Form className="accept-form">
          <Form.Group>
            <Form.Label>First name</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="First name"
              defaultValue=""
              onChange={onChangeFirstName}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Last name</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Last name"
              defaultValue=""
              onChange={onChangeLastName}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Password</Form.Label>
            <Form.Control
              required
              type="password"
              placeholder="Password"
              defaultValue=""
              onChange={onChangePassword}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              required
              type="password"
              placeholder="Confirm Password"
              defaultValue=""
              onChange={onChangeConfirmPassword}
            />
          </Form.Group>

          <Row className="mt-4">
            <Col>
              <button
                type="submit"
                className="w-100 btn accept-cancel-button position-relative"
                onClick={handleSubmit}
              >
                Cancel
              </button>
            </Col>
            <Col>
              <button
                type="submit"
                className="w-100 btn accept-button position-relative"
                onClick={handleSubmit}
              >
                <span className={isLoading ? 'd-none' : ''}> Accept</span>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className={isLoading ? '' : 'd-none'}
                />
              </button>
            </Col>
          </Row>
        </Form>
      )}
    </Container>
  );
};
