import { FC, useCallback, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Button, Col, InputGroup, Row } from 'react-bootstrap';

import { ErrorMessage } from '@hookform/error-message';
import { useForm } from 'react-hook-form';
import { useAuth, useNotifications, useRequest } from '../../../modules/hooks';
import EyeHideIcon from '../../../assets/eye-hide.svg';
import EyeShowIcon from '../../../assets/eye-show.svg';

import { endpoints } from '../../../modules/mappers/urls';
import { getApiErrorMessage } from '../../../modules/utils/transform';

interface UserDetailsModalProps {
  show: boolean;
  handleClose: () => void;
  resetCodeValue: string;
  guestEmail: string;
}
interface ResetPasswordForm {
  email: string;
  code: string;
  password1: string;
  password2: string;
}

export const ResetCodeModal: FC<UserDetailsModalProps> = ({
  show,
  handleClose,
  guestEmail,
}) => {
  const { setSimpleToasts } = useNotifications()!;
  const { credentialsInfo } = useAuth()!;
  const [isPassShow, setIsPassShow] = useState(false);
  const [isConfirmPassShow, setIsConfirmPassShow] = useState(false);

  const urlResetPassword = `${endpoints.USERS}/reset-password-confirmation`;
  const [{ data: resetResult, error: resetError }, forgotPasswordSubmit] = useRequest(
    urlResetPassword,
    'post',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<ResetPasswordForm>({ mode: 'onChange' });

  useEffect(() => {
    if (guestEmail) {
      setValue('email', guestEmail);
    }
  }, [guestEmail]);

  const changePassword = async (data: ResetPasswordForm) => {
    const validated = await trigger(['email', 'code', 'password1', 'password2']);
    const { email, code, password1 } = data;

    if (validated) {
      forgotPasswordSubmit({
        data: { email, code, password: password1 },
      });
    }
  };

  useEffect(() => {
    if (resetResult) {
      handleClose();
      setSimpleToasts({
        type: 'success',
        message: 'Password has been reset successfully',
        show: true,
      });
    }

    if (resetError) {
      handleClose();
      const message = getApiErrorMessage(resetError);
      setSimpleToasts({
        type: 'danger',
        message,
        show: true,
      });
    }
  }, [resetResult, resetError]);

  const onPasswordToggle = useCallback(() => {
    setIsPassShow(!isPassShow);
  }, [isPassShow]);

  const onConfirmPasswordToggle = useCallback(() => {
    setIsConfirmPassShow(!isConfirmPassShow);
  }, [isConfirmPassShow]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="post-editor-modal modal-200w"
      backdrop="static"
      centered
    >
      <>
        <Modal.Header>
          <Modal.Title className="editor-title">Reset Password</Modal.Title>
          <span className="editor-sub-title">
            Please enter the reset code sent to your email and the new password.
          </span>
        </Modal.Header>

        <form onSubmit={handleSubmit(changePassword)}>
          <Modal.Body>
            <Row className="mt-16px">
              <Col>
                <Form.Label className="editor-title-label">Reset Code</Form.Label>
                <Form.Control
                  className="editor-control"
                  placeholder="Enter the reset code..."
                  {...register('code', {
                    required: 'This is required.',
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="code"
                  render={({ message }) => (
                    <span className="password-error-text">{message}</span>
                  )}
                />
              </Col>
            </Row>
            <Row className="mt-16px">
              <Col>
                <Form.Label className="editor-title-label">
                  Enter the new password
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    className="editor-control"
                    type={isPassShow ? 'text' : 'password'}
                    placeholder="Enter your password..."
                    {...register('password1', {
                      required: 'This is required.',
                      pattern: {
                        value: /^(?=.*[\W_])(?=.*\d)(?=.*[A-Z]).{9,}$/,
                        message:
                          'Password must contain: one number, one special character, one uppercase letter and be at least 9 characters long',
                      },
                    })}
                  />

                  <InputGroup.Text onClick={onPasswordToggle}>
                    <img
                      className="eye-icon-container"
                      alt="eye-img"
                      src={isPassShow ? EyeHideIcon : EyeShowIcon}
                    />
                  </InputGroup.Text>
                </InputGroup>

                <ErrorMessage
                  errors={errors}
                  name="password1"
                  render={({ message }) => (
                    <span className="password-error-text">{message}</span>
                  )}
                />
              </Col>
            </Row>
            <Row className="mt-16px">
              <Col>
                <Form.Label className="editor-title-label">
                  Confirm your password
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    className="editor-control"
                    type={isConfirmPassShow ? 'text' : 'password'}
                    placeholder="Confirm your password..."
                    {...register('password2', {
                      required: 'This is required.',
                      validate: value =>
                        value === getValues('password1') || 'Both passwords must match',
                    })}
                  />
                  <InputGroup.Text onClick={onConfirmPasswordToggle}>
                    <img
                      className="eye-icon-container"
                      alt="eye-img"
                      src={isConfirmPassShow ? EyeHideIcon : EyeShowIcon}
                    />
                  </InputGroup.Text>
                </InputGroup>
                <ErrorMessage
                  errors={errors}
                  name="password2"
                  render={({ message }) => (
                    <span className="password-error-text">{message}</span>
                  )}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer
            style={{
              display: 'flex',
              justifyContent: 'end',
              marginTop: '24px',
            }}
          >
            <Button className="cancel-button" onClick={handleClose}>
              Cancel
            </Button>
            <Button className="save-button" type="submit">
              Change Password
            </Button>
          </Modal.Footer>
        </form>
      </>
    </Modal>
  );
};
